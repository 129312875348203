import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    standalone: true,
})

export class HomepageComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}
