import { CardDto } from './dto/card.dto';

export const playersName = { p1: 'p1', p2: 'p2' };
export const defaultSpeed: number = 1000;
export const deck: CardDto[] = [
    { value: 1, label: 'As', symbol: 'Coeur' },
    { value: 2, label: '2', symbol: 'Coeur' },
    { value: 3, label: '3', symbol: 'Coeur' },
    { value: 4, label: '4', symbol: 'Coeur' },
    { value: 5, label: '5', symbol: 'Coeur' },
    { value: 6, label: '6', symbol: 'Coeur' },
    { value: 7, label: '7', symbol: 'Coeur' },
    { value: 8, label: '8', symbol: 'Coeur' },
    { value: 9, label: '9', symbol: 'Coeur' },
    { value: 10, label: '10', symbol: 'Coeur' },
    { value: 11, label: 'Valet', symbol: 'Coeur' },
    { value: 12, label: 'Dame', symbol: 'Coeur' },
    { value: 13, label: 'Roi', symbol: 'Coeur' },
    { value: 1, label: 'As', symbol: 'Carreau' },
    { value: 2, label: '2', symbol: 'Carreau' },
    { value: 3, label: '3', symbol: 'Carreau' },
    { value: 4, label: '4', symbol: 'Carreau' },
    { value: 5, label: '5', symbol: 'Carreau' },
    { value: 6, label: '6', symbol: 'Carreau' },
    { value: 7, label: '7', symbol: 'Carreau' },
    { value: 8, label: '8', symbol: 'Carreau' },
    { value: 9, label: '9', symbol: 'Carreau' },
    { value: 10, label: '10', symbol: 'Carreau' },
    { value: 11, label: 'Valet', symbol: 'Carreau' },
    { value: 12, label: 'Dame', symbol: 'Carreau' },
    { value: 13, label: 'Roi', symbol: 'Carreau' },
    { value: 1, label: 'As', symbol: 'Trèfle' },
    { value: 2, label: '2', symbol: 'Trèfle' },
    { value: 3, label: '3', symbol: 'Trèfle' },
    { value: 4, label: '4', symbol: 'Trèfle' },
    { value: 5, label: '5', symbol: 'Trèfle' },
    { value: 6, label: '6', symbol: 'Trèfle' },
    { value: 7, label: '7', symbol: 'Trèfle' },
    { value: 8, label: '8', symbol: 'Trèfle' },
    { value: 9, label: '9', symbol: 'Trèfle' },
    { value: 10, label: '10', symbol: 'Trèfle' },
    { value: 11, label: 'Valet', symbol: 'Trèfle' },
    { value: 12, label: 'Dame', symbol: 'Trèfle' },
    { value: 13, label: 'Roi', symbol: 'Trèfle' },
    { value: 1, label: 'As', symbol: 'Pique' },
    { value: 2, label: '2', symbol: 'Pique' },
    { value: 3, label: '3', symbol: 'Pique' },
    { value: 4, label: '4', symbol: 'Pique' },
    { value: 5, label: '5', symbol: 'Pique' },
    { value: 6, label: '6', symbol: 'Pique' },
    { value: 7, label: '7', symbol: 'Pique' },
    { value: 8, label: '8', symbol: 'Pique' },
    { value: 9, label: '9', symbol: 'Pique' },
    { value: 10, label: '10', symbol: 'Pique' },
    { value: 11, label: 'Valet', symbol: 'Pique' },
    { value: 12, label: 'Dame', symbol: 'Pique' },
    { value: 13, label: 'Roi', symbol: 'Pique' }
];