<nav class="flex items-center justify-between flex-wrap bg-[#125699] p-6">
    <div class="flex items-center flex-shrink-0 text-white mr-6">
        <a class="text-white font-semibold text-xl" routerLink="/">
            <img src="./assets/logoHollux3blanc.png" alt="logo hollux">
        </a>
    </div>
    <div class="block lg:hidden">
        <button id="toggleButton"
            class="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white"
            (click)="toggleNavbar()">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <path d="M0 4h20v2H0zm0 5h20v2H0zm0 5h20v2H0z" />
            </svg>
        </button>
    </div>
    <div id="navbarLinks" [ngClass]="{'hidden': isNavbarOpen, 'lg:flex': !isNavbarOpen}"
        class="w-full flex-grow lg:flex lg:items-center lg:w-auto">
        <div class="text-sm lg:flex-grow">
            <a routerLink="/soloparty" routerLinkActive="active"
                class="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4" (click)="closeNavbar()">
                Jouer
            </a>
        </div>



    </div>
</nav>